class WareHouseNamesMappings {
  constructor() {
    this.nameMappings = {
      itemTypes: "Famiglie Bar",
      items: "Prodotti",
      orders: "Ordini",
      movementItems: ["Movimenti del", "Magazzino"],
      movementUploads: "Carica Magazzino",
      movementUnloads: "Scarica Magazzino",
      movementAdjustments: "Inventario",
      packageTypes: 'Tipi Confezioni',
      unitOfMeasures: 'Unità Di Misura',
      packageType: 'Tipo Confezione',
      unitOfMeasure: 'Unità Di Misura',
      departments: 'Reparti P.',
    };

    this.iconWarehouseMappings = {
      itemTypes: "mdi-silverware",
      items: "mdi-spray-bottle",
      orders: "mdi-package-variant",
      movementItems: "mdi-link-variant",
      movementUploads: "mdi-package-up",
      movementUnloads: "mdi-dolly",
      movementAdjustments: "mdi-human-dolly",
      packageTypes: 'mdi-package-variant',
      unitOfMeasures: 'mdi-ruler-square-compass',
      departments: 'mdi-home-city',
    };

    this.iconOperationsMappings = {
      // many
      add: "mdi-plus",
      edit: "mdi-pencil",
      view: "mdi-eye-outline",
      change_warehouse_color: "mdi-palette",
    };
  }

  getHumanName(codedName) {
    return this.nameMappings[codedName];
  }

  getCodedName(humanName) {
    return Object.keys(this.nameMappings).find(
      (key) => this.nameMappings[key] === humanName
    );
  }

  getWarehouseIcon(warehouseName) {
    return this.iconWarehouseMappings[warehouseName];
  }

  getOperationIcon(operationName) {
    return this.iconOperationsMappings[operationName];
  }
}

export default new WareHouseNamesMappings();
