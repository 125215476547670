<template>
  <div :key="componentKey">
    <v-row v-for="(recentOperationPair, index) in groupedRecentOperations" v-bind:key="index">
      <v-col cols="12" md="6" lg="6" class="px-2 py-0">
        <RecentOperationResult
          :operation="recentOperationPair[0]"
        ></RecentOperationResult>
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" md="6" lg="6" class="px-2 py-0">
        <RecentOperationResult
          v-if="recentOperationPair[1]"
          :operation="recentOperationPair[1]"
        ></RecentOperationResult>
        <v-divider v-if="recentOperationPair[1]" ></v-divider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RecentOperationResult from './RecentOperationResult'
import _ from 'lodash'

export default {
  name: "RecentOperationsResults",
  components: {
    RecentOperationResult
  },
  data: function() {
    return {
      componentKey: 0
    }
  },
  props: {
    operations: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  computed: {
    groupedRecentOperations() {
      let grouped = []
      for(var i = 0; i < this.operations.length; i++) {
        let index = Math.floor(i / 2)
        if(!grouped[index]) {
          grouped[index] = []
        }
        grouped[index].push(this.operations[i])
      }
      return grouped
    }
  }
}
</script>

<style>

</style>