
import warehouseList from './warehouse.json'
import warehouseNamesMappings from './warehouseNamesMappings'
import recentOperationsDatabase from '@/services/offline/warehouse/warehouseRecentOperations.database.js'


class warehouseManagment {
  constructor() {
    this.warehouse = warehouseList
    this._calculateRegistriesText()
  }

  list() {
    return Promise.resolve(this.warehouse)
  }

  lastRecentOperationsList(number = 8) {
    return new Promise((resolve, reject) => {
      recentOperationsDatabase.getLastOperations(number).then((operations) => {
        resolve(operations)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  _calculateRegistriesText() {
    for (let i = 0; i < this.warehouse.length; i++) {
      this.warehouse[i].text = warehouseNamesMappings.getHumanName(this.warehouse[i].name)
      this.warehouse[i].icon = warehouseNamesMappings.getWarehouseIcon(this.warehouse[i].name)
    }
  }
}

export default new warehouseManagment()